@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

body {
  margin: 0;
  padding: 0;
  color: white;
}

form {

}

.wrapper {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  display: grid;
  background: rgb(2, 0, 36);
  height: 100vh;
  width: 100vw;
}

.bg {
  background-size: 300% 300%;
  background-image: linear-gradient(
                  -45deg,
                  rgb(0, 0, 0) 0%,
                  rgb(13, 21, 70) 25%,
                  rgb(106, 13, 134) 51%,
                  rgb(153, 19, 70) 100%
  );
  animation: AnimateBG 20s ease infinite;
}

@keyframes AnimateBG {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}


.button {
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  color: white;
  height: 50px;
  text-align: center
}

.button-gradient {
  background: rgb(214,90,199);
  background: linear-gradient(126deg, rgba(214,90,199,1) 0%, rgba(51,155,191,1) 28%, rgba(27,124,213,1) 48%, rgba(237,102,232,1) 100%);
}

.menu {
  display: grid;
  justify-content: right;

  .login {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}
.logo {
  user-select: none;
  align-items: center;
  justify-items: center;
  color: #ac25ac;
  display: inline-grid;
}

.info {
  align-items: center;
  justify-items: center;
}

.color-text {
  background: -webkit-linear-gradient(77.38deg, #fe3b76 9.15%, #af29ce 49.65%, #2931dd 90.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 180px;
}


.wrapper-centred {
  grid-template-columns: 1fr;
  display: grid;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
                  -45deg,
                  rgb(0, 0, 0) 0%,
                  rgb(13, 21, 70) 25%,
                  rgb(106, 13, 134) 51%,
                  rgb(153, 19, 70) 100%
  );
}

.modal-login{
  font-family: 'IBM Plex Sans', sans-serif;
  display: grid;
  grid-row-gap: 7px;
  justify-content: center;
  align-content: center;
  text-align: center;

  h4 {
    color: white;
    text-align: center;
    font-size: 18px;
    margin: 0;
  }

  form {
    justify-content: center;
    align-content: center;
    display: grid;
    grid-gap: 5px;
    height: 100%;
    align-items: center;

    #message-error {
      text-align: center;
      color: #ba12a4;
    }

    input[type="submit"] {
      border: 5px;
      color: white;
      background: #f6397f;
      height:60px;
      border-radius: 5px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      &:hover {
        background: #f6176b;
      }
      &:active {
        background: #ff81b4;

      }
    }

    input[type="text"],input[type="password"]  {
      padding-left: 10px;
      border-radius: 5px;
      border: 1px solid #746d6d;
      height: 35px;
      min-width: 350px;
      font-weight: 500;
      font-family: 'IBM Plex Sans', sans-serif;
      &::placeholder {
        font-weight: 600;
      }
    }


  }
  a {
    color:white;
  }
}


.hidden {
  display: none;
}

.contacts {
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  font-weight: 600;
}

.button-registration a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}

.button-back {
  cursor: pointer;
  position: fixed;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 5%;
  left: 3%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  background: #28242c;
  height: 50px;
  width: 172px;
  color: #ffffff;
}

.admin-menu {
  margin-top: 50px;
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  .table-menu {
    border-bottom: 1px solid black;
  }
  div {
    background: #7761c1;
    display:grid;
    grid-template-columns: 1fr 3fr 1fr 2fr 2fr 1fr;
    min-height: 70px;
    align-content: center;
    span {
      border-right: 1px solid black;
    }

  }

  button {
    border: none;
    &:hover {
      background: lightblue;
    }
  }
}

.button-support {
  position: fixed;
  top: 93%;
  left: 95%;
  background: #540e76;
  border-radius: 20px;
  width: 65px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
}