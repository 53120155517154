@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

body {
  background: hsl(270deg, 96%, 17%);
  min-height: 100vh;
 max-width: 100vw;
}
#root {
  min-height: 100vh;
}

.app {
  background: hsl(270deg, 96%, 17%);
}

.tile-wrapper {
  margin-top: 50px;
  font-family: 'IBM Plex Sans', sans-serif;
  display: grid;
  justify-content: center;
  grid-column-gap: 15px;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 500px));
  grid-template-rows: repeat(auto-fit, minmax(300px, 350px));
  min-height: 100vh;
  width: 100%;

  align-content: center;
}

a  {
  display: inline-grid;
  text-decoration: none;
}

 .tile {
   display: grid;
   justify-content: center;
   justify-items: center;
   border-radius: 15px;
   background: rgb(95,39,215);
   background: linear-gradient(169deg, rgb(95, 39, 215) 0%, rgb(168, 68, 222) 48%, rgb(214, 88, 222) 100%);

   h3 {
     color:white;
     text-transform: uppercase;
     font-size: 25px;
    text-align: center;
  }

}
.centred {
  align-content: center;
}

.tile:hover {
  background: linear-gradient(-169deg, rgb(95, 39, 215) 0%, rgb(168, 68, 222) 48%, rgb(214, 88, 222) 100%);
  box-shadow: -7px 9px 20px -4px rgba(115,113,113,0.72);
  -webkit-box-shadow: -7px 9px 20px -4px rgba(115,113,113,0.72);
  -moz-box-shadow: -7px 9px 20px -4px rgba(115,113,113,0.72);
  transition: box-shadow .2s linear,-webkit-box-shadow .2s linear;
}

.tile-disabled{
  background: #51266e;
  pointer-events: none;
  .toggle:before {
    background: lightgray;
  }
  .slider-container{
    opacity: 0;
    background: lightgray;
  }
  .toggle {
    pointer-events: all;
  }
}


//Toggle

.toggle-container {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.switch {
  display: inline-grid;
  position: relative;
  width: 60px;
  height: 34px;
}

input:checked + .toggle {
  background-color: #ffffff;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #000000;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 5px;
  background-color: #581a7a;
  -webkit-transition: .4s;
  transition: .4s;
}

//slider

.slider-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  text-align: center;
  font-size: 1.5em;
  justify-content: center;
  font-weight: 800;
  align-content: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #4a35356b;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #9a4ee3;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #ffffff;
  cursor: pointer;
}

//Action buttons

.button-police {
  cursor: pointer;
  position: fixed;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 90%;
  left:90%;
  border-radius: 50px;
  font-weight: 800;
  font-size: 35px;
  background: #be2e2e;
  height: 50px;
  width: 50px;

  color: #ffffff;
}
.title-hidden {
  display: none;
}

.button-voice {
  cursor: pointer;
  position: fixed;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 90%;
  left:5%;
  border-radius: 20px;
  font-weight: 400;
  font-size: 20px;
  background: linear-gradient(169deg, rgb(175 175 175) 0%, rgb(230 184 255) 48%, rgb(234 189 237) 100%);
  height: 50px;
  width: 120px;

  color: #ffffff;
}

.form-add_option {
  grid-template-columns: 3fr 1fr;
  display: grid;
  height: 40px;

  button {
    border: none;
    background: rgba(153, 47, 193, 0.79);
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
    color: white;
    font-weight: 600;
  }
  button:hover{
    background: rgba(169, 91, 195, 0.79);
  }
  input[type="text"] {
    border: none;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
}


//Select
.select-list {
  width: 80%;
  text-align: center;
  height: 40px;
}
.select-list select {
  width: 80%;
  border-radius: 15px;
  height: 40px;
  text-align: center;
  border: none;
  background: #e678f5;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.select-selected {
  width: 80%;
  background-color: #9357ea;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

//Menu
nav {
  position: fixed;
  top: 0;
  background: #8837da;
  height: 41px;
  left: 0;
  right: 0;
  display: grid;
  justify-content: right;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  color: white;
  z-index: 5;
  a {
    color: white;
    text-align: right;
    font-weight: 600;

  }
  a:nth-last-child(n) {
    padding-right: 50px;
  }
}

